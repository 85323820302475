exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-about-insgc-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/about-insgc.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-about-insgc-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-about-organizers-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/about-organizers.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-about-organizers-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-advertising-opportunities-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/advertising-opportunities.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-advertising-opportunities-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-awards-about-near-surface-geophysics-awards-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/awards-about-near-surface-geophysics-awards.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-awards-about-near-surface-geophysics-awards-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-awards-categories-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/awards-categories.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-awards-categories-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-awards-evaluation-process-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/awards-evaluation-process.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-awards-evaluation-process-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-awards-nomination-fees-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/awards-nomination-fees.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-awards-nomination-fees-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-awards-nomination-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/awards-nomination.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-awards-nomination-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-awards-nomination-process-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/awards-nomination-process.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-awards-nomination-process-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-cancellation-refunds-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/cancellation-refunds.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-cancellation-refunds-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-conference-abstract-information-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/conference-abstract-information.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-conference-abstract-information-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-conference-themes-and-topics-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/conference-themes-and-topics.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-conference-themes-and-topics-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-contact-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/contact.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-contact-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-delegate-registration-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/delegate-registration.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-delegate-registration-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-exhibition-opportunities-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/exhibition-opportunities.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-exhibition-opportunities-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-industry-presentation-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/industry-presentation.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-industry-presentation-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-partners-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/partners.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-partners-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-privacy-policy-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/privacy-policy.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-privacy-policy-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-quick-registration-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/quick-registration.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-quick-registration-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-registration-cost-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/registration-cost.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-registration-cost-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-resistivity-and-induced-polarization-workshop-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/resistivity-and-induced-polarization-workshop.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-resistivity-and-induced-polarization-workshop-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-sponsorship-opportunities-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/sponsorship-opportunities.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-sponsorship-opportunities-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-technical-committee-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/technical-committee.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-technical-committee-mdx" */),
  "component---src-templates-insidepage-jsx-content-file-path-content-terms-and-conditions-mdx": () => import("./../../../src/templates/insidepage.jsx?__contentFilePath=/Users/rashikkathuria/Works/Projects/AquaFoundation/Websites/insg-website/content/terms-and-conditions.mdx" /* webpackChunkName: "component---src-templates-insidepage-jsx-content-file-path-content-terms-and-conditions-mdx" */)
}

